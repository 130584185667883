.container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}

.dropdown-toggle {
  padding: 0 !important;
  border: none !important;
  border-color: transparent !important;
  background-color: transparent !important;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    border-color: transparent !important;
    background-color: transparent !important;
  }
  &::after {
    vertical-align: middle !important;
  }
}

.dropdown-item {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  transition: all 0.2s;
  &:active,
  &:hover,
  &:focus {
    color: var(--black);
    background: var(--grey);
    transition: all 0.2s;
  }
}

.Toastify {
  .Toastify__toast-container {
    width: auto !important;
    z-index: 99999 !important;
    @media only screen and (max-width: 500.98px) {
      width: 90% !important;
    }
    @media only screen and (max-width: 478.98px) {
      width: 90% !important;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      bottom: 50px;
    }
  }
  .Toastify__toast-body {
    font-weight: 500;
    font-size: 14px;
    color: #18213a;
    padding-right: 12px;
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.005em;
      color: #124de3;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .Toastify__close-button {
    align-self: center;
  }
  .toast-grade {
    width: auto !important;
    &.Toastify__toast {
      border-radius: 12px;
    }
    &.Toastify__toast--error {
      background-color: #f9dce1;
      border-radius: 12px;
    }
    &.Toastify__toast--green {
      background-color: #dffcee;
      border-radius: 12px;
    }
    .Toastify__toast-icon {
      height: 36px;
      width: 36px;
    }
    .close-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 15px;
      margin-left: 10px;
    }
    .toast-grade-content {
      font-size: 14px;
      color: #18213a;
      font-weight: 500;
      span {
        color: #124de3;
      }
    }
  }
}
