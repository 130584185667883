.optionsWrapper {
  position: sticky;
  left: 0;
  padding: 24px;
  .accordionSummary {
    [class~='MuiAccordionSummary-content'] {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
      > button {
        padding: 2px 12px;
      }
    }
    .chevronIcon {
      width: 16px;
      height: 16px;
    }
  }
}
.numberInput {
  margin: 0;
  > input {
    padding: 5px 16px;
    border-color: var(--gray);
  }
}
.submitButtonWrapper {
  display: flex;
  align-items: start;
  > button {
    padding: 6px 16px;
    margin-top: 24px;
  }
}

.breakpointWrapper {
  label {
    font-weight: 600;
    margin-right: 8px;
  }
  > p {
    margin: 2px 2px 0;
    color: var(--danger);
    white-space: wrap;
  }

  .switch {
    [class~='MuiSwitch-switchBase'][class~='Mui-checked'] {
      [class~='MuiSwitch-thumb'] {
        color: var(--primary);
      }
      + [class~='MuiSwitch-track'] {
        background-color: var(--chipColor);
      }
    }
  }
  .breakpointList {
    margin: 8px 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    svg {
      margin-top: 6px;
      filter: brightness(0);
      width: 20px;
      height: 20px;
    }
    .breakpointItem {
      display: flex;
      align-items: start;
      gap: 8px;
      > span {
        margin-top: 4px;
        line-break: anywhere;
      }

      .breakpointInput {
        margin: 0;
        width: 240px;
        > input {
          padding: 4px 8px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin: 8px 0 0;
      .breakpointItem {
        .breakpointInput {
          width: 160px;
        }
      }
    }
  }
  .breakpointButton {
    > button {
      width: 100%;
      background: transparent;
      border: 1px solid var(--white);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      transition: all 0.2s;
      > svg {
        margin: 0;
      }
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
  }
}
.noteSubTable{
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgb(138, 180, 248);
  background: rgb(232, 240, 254);
  border-left: 5px solid rgb(138, 180, 248);
  margin-bottom: 8px;
}
.btnUploadData{
  &:hover{
    cursor: pointer;
  }
    display: block;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    border: 2px solid var(--primary);
    color: var(--black);
    background: transparent;
    padding: 6px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.25s;
}
.disableButton{
  pointer-events: none;
  opacity: 0.5;     
  cursor: not-allowed;
}