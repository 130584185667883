.container {
  > label {
    font-weight: 600;
    margin: 0 2px 2px;
  }
  > p {
    margin: 2px 2px 0;
    color: var(--danger);
    white-space: wrap;
  }
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  label {
    font-weight: 600;
    margin: 0 2px 2px;
  }
  .datePickerInput {
    input {
      padding: 6.5px 16px;
    }
    fieldset {
      border-width: 1px !important;
      border-color: var(--gray);
    }
    [class^="MuiInputBase-root"]:hover {
      fieldset {
        border-color: var(--primary);
      }
    }
  }
}
