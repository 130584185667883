.container {
  [class~="MuiPaper-root"] {
    border-radius: 12px;
    color: var(--black);
    background: var(--backgroundLight);
    width: 100%;
    @media screen and (max-width: 768px) {
      margin: 16px;
    }
  }
  [class~="MuiTypography-root"] {
    padding: 24px;
    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }
  [class~="MuiDialogContent-root"] {
    padding-bottom: 0;
    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }
  }
  [class~="MuiDialogActions-root"] {
    padding: 0 24px 24px;
    gap: 12px 24px;
    flex-wrap: wrap;
    margin-top: 4px;
    > button {
      margin: 0;
    }
  }
  .title {
    padding: 16px 24px 0;
    font-size: 20px;
    font-weight: 600;
  }
}
.uploadWrapper {
  cursor: pointer;
  position: relative;
  height: 160px;
  padding: 8px;
  border-radius: 4px;
  border: 1px dashed var(--gray);
  flex: 1;
}

.fileAllowed {
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
}

.uploadBox {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--grey);
  border-radius: 4px;
  > input {
    display: none;
  }
  p {
    padding: 0 4px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
  > svg {
    width: 40px;
    height: 40px;
    path {
      fill: var(--black);
    }
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > button {
    line-height: normal;
  }
}