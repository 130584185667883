.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  > label {
    font-weight: 600;
    margin: 0 2px 2px;
    > span {
      font-weight: 500;
    }
  }
  > input {
    width: 100%;
    background: transparent !important;
    border-radius: 4px;
    border: 1px solid var(--black);
    &::-ms-input-placeholder {
      color: var(--gray);
    }
    &::placeholder {
      color: var(--gray);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line {
      transition: background-color 0s 600000s, color 0s 600000s;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: var(--primary);
    }
  }
  .subLabel {
    margin: 6px 2px 0;
    font-style: italic;
    color: var(--gray);
  }
  .errorMessage {
    margin: 2px 2px 0;
    color: var(--danger);
    white-space: wrap;
  }
}
.isDisabled{
  input{
    background-color: var(--bs-secondary-bg) !important;
    &:hover{
      outline: none;
      box-shadow: none;
      border-color: black;
    }
  }
  
}
.isPassword {
  position: relative;
  > input {
    padding: 10px 48px 10px 16px;
  }
  .passwordIcon {
    position: absolute;
    top: 25px;
    right: 16px;
    height: 46px;
    display: flex;
    align-items: center;
    > svg {
      cursor: pointer;
      width: 20px;
      height: 20px;
      > path {
        fill: var(--primary);
      }
    }
  }
}

.inputCustom{
  margin: 0 !important;
  width: 100%;
  .lableValue{
    background: #0000 !important;
    border: 1px solid var(--black);
    border-radius: 4px;
    padding: 4px 10px;
    width: 100%;
    padding-right: 15px;
    min-width: 100px;
    min-height: 34px;
  }
  .valueplaceholder{
    color: gray;
    font-weight: 400;
  }
}
.lableValuePercentage{
  padding-left: 24px !important;
}

