.container {
  > label {
    font-weight: 600;
    margin: 0 2px 2px;
  }
  > p {
    margin: 2px 2px 0;
    color: var(--danger);
    white-space: wrap;
  }
}
.actionChip {
  cursor: pointer;
  height: 24px !important;
  color: var(--primary) !important;
  background-color: var(--chipBackground) !important;
  text-transform: none;
}
.moreSelected {
  color: var(--darkGray);
  font-size: 14px;
  line-height: 2;
  margin-left: auto;
  order: 1;
}
.groupHeading.collapsible {
  margin: 0;
  cursor: pointer !important;
  background-color: var(--lightGrey);
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grey);
  .regionName {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: capitalize;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  + div {
    max-height: 1000px;
    overflow: hidden;
  }
  &:not(.collapse) {
    .regionName {
      svg {
        transform: rotate(180deg);
      }
    }
    + div {
      padding: 8px 0;
    }
  }
  &.collapse {
    + div {
      max-height: 0;
    }
  }
}
