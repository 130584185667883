.headerWrapper {
  box-shadow: 0px 2px 6px rgba(38, 43, 67, 0.14) !important;
  box-shadow: var(--mui-customShadows-xs);
  .toolBar {
    position: relative;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    :hover{
      cursor: pointer;
    }
    > img {
      height: 48px;
      width: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  .appName {
    :hover{
      cursor: pointer;
    }
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: black;
    @media only screen and (max-width: 991.98px) {
      display: none;
    }
  }
}
