@import '~bootstrap/scss/bootstrap';
@import './fonts.scss';
@import './config.scss';

:root {
  --gradient: transparent linear-gradient(90deg, #e15757 0%, #da3838 100%);
  --primary: #fe0000;
  --black: #18171e;
  --white: #ffffff;
  --blue: #66afff;
  --red: #cc3939;
  --yellow: #fcd965;
  --yellowLight: #ffeeba;
  --brown: #856404;
  --gray: #778393;
  --green: #408140;
  --darkGray: #4c4e64de;
  --grey: #cbd5e1;
  --lightGrey: #cbd5e14d;
  --greyActive: #cccccc25;
  --backgroundLight: #f7f7f9;
  --warning: #e57722;
  --danger: #ff0000;
  --primaryBoxShadow: var(--primary) 0px 2px 20px 0px;
  --chipBackground: #ff666631;
  --chipColor: #ff6666;
  --rowBackground: #ffdede;

  --commonBoxShadow: 0 1px 4px rgba(0, 0, 0, 0.03), 0 4px 10px rgba(0, 0, 0, 0.02), 0 5px 30px rgba(0, 0, 0, 0.06);
  --rightBoxShadow: var(--black) 6px 0 3px -5px;
  --leftBoxShadow: var(--black) -6px 0 5px -5px;
  --sidebarBoxShadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  --chartModalBoxShadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  --rowColorPrimary: #ffe699;
  --rowColorSecondary: #fff2cc;
  --rowColorPrimary1: #bdd7ee;
  --rowColorSecondary1: #ddebf7;
  --rowColorPrimary2: #c6e0b4;
  --rowColorSecondary2: #e2efda;
  --rowColorPrimary3: #f8cbad;
  --rowColorSecondary3: #fce4d6;
  --rowColorPrimary4: #dbdbdb;
  --rowColorSecondary4: #ededed;
}

html,
body {
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden !important;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: var(--black);
  background-color: var(--backgroundLight);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .cursor-pointer {
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 6px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: none;
    background: var(--gray);
  }
  ::-webkit-scrollbar-corner {
    background: var(--white);
  }
  p {
    margin-bottom: 0;
  }
  a {
    color: var(--primary);
  }
}
