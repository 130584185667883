.container {
  height: auto !important;
  min-height: 100vh;
  display: block;
  position: relative;
  top: 0;
}

.main {
  position: relative;
  float: right;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  min-height: 100%;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  @media screen and (min-width: 900px) {
    width: calc(100% - 260px);
  }
  .header {
    padding: 16px;
    display: flex;
    justify-content: end;
    > .toggleButton {
      display: none;
      margin-right: auto;
      @media screen and (max-width: 900px) {
        display: block;
      }
      padding: 4px 12px;
      > svg {
        path {
          fill: var(--black);
        }
      }
    }
  }
  .content {
    padding: 32px 16px;
  }
}
