.descriptionBox{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    padding: 16px;
    color: var(--brown);
    background-color: var(--yellowLight);
    border-radius: 4px;
    .subTitle{
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 24px;
    }
}