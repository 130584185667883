.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
}

.formContainer {
  width: 100%;
  background: var(--white);
  overflow-y: auto;
  > .formWrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > .formContent {
      width: 100%;
      max-width: 550px;
      padding: 36px;
    }
  }
}
