.container {
  [class~="MuiPaper-root"] {
    border-radius: 12px;
    color: var(--black);
    background: var(--backgroundLight);
    width: 100%;
    @media screen and (max-width: 900px) {
      margin: 16px;
    }
  }
  [class~="MuiTypography-root"] {
    padding: 24px;
  }
  [class~="MuiDialogContent-root"] {
    padding-bottom: 0;
  }
  [class~="MuiDialogActions-root"] {
    padding: 0 24px 24px;
    gap: 12px 24px;
    > button {
      margin: 0;
    }
  }

  .title {
    padding: 0;
    margin: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 900px) {
      -webkit-line-clamp: 2;
    }
  }

  .breakpointWrapper {
    label {
      font-weight: 600;
      margin-right: 8px;
    }
    > p {
      margin: 2px 2px 0;
      color: var(--danger);
      white-space: wrap;
    }

    .switch {
      [class~="MuiSwitch-switchBase"][class~="Mui-checked"] {
        [class~="MuiSwitch-thumb"] {
          color: var(--primary);
        }
        + [class~="MuiSwitch-track"] {
          background-color: var(--chipColor);
        }
      }
    }
    .breakpointList {
      margin: 8px 24px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      svg {
        margin-top: 6px;
        filter: brightness(0);
        width: 20px;
        height: 20px;
      }
      .breakpointItem {
        display: flex;
        align-items: start;
        gap: 8px;
        > span {
          margin-top: 4px;
          line-break: anywhere;
        }

        .breakpointInput {
          margin: 0;
          width: 240px;
          > input {
            padding: 4px 8px;
          }
        }
      }
      @media screen and (max-width: 768px) {
        margin: 8px 0 0;
        .breakpointItem {
          .breakpointInput {
            width: 160px;
          }
        }
      }
    }
    .breakpointButton {
      > button {
        width: 100%;
        background: transparent;
        border: 1px solid var(--white);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        transition: all 0.2s;
        > svg {
          margin: 0;
        }
        &:hover {
          background: var(--greyActive);
          transition: all 0.2s;
        }
      }
    }
  }
  .dialogAction {
    justify-content: space-between;
    [class~="MuiPaper-root"] {
      width: fit-content;
      padding: 0;
    }
  }
}
