.container {
  [class~="MuiPaper-root"] {
    border-radius: 12px;
    color: var(--black);
    background: var(--backgroundLight);
    width: 100%;
    @media screen and (max-width: 768px) {
      margin: 16px;
    }
  }
  [class~="MuiTypography-root"] {
    padding: 24px;
    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }
  [class~="MuiDialogContent-root"] {
    padding-bottom: 0;
    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }
  }
  [class~="MuiDialogActions-root"] {
    padding: 0 24px 24px;
    gap: 12px 24px;
    flex-wrap: wrap;
    margin-top: 4px;
    > button {
      margin: 0;
    }
  }
}
.pasteButton {
  svg {
    width: 16px;
    height: 16px;
  }
}
.inputContainer {
  position: relative;
  max-width: 200px;
  margin: 0;
  > input {
    padding: 4px 10px;
  }
  &.percentage {
    > input {
      padding-left: 24px;
    }
  }
  &.percentage::after {
    content: "%";
    position: absolute;
    top: 6px;
    left: 0.5em;
    transition: all 0.05s ease-in-out;
  }
}
