.avatarWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 12px;
    height: 12px;
  }
}
.userName {
  font-weight: 500;
}

.plan {
  font-size: 14px;
  font-weight: 400;
}

.menu {
  [class~='MuiMenu-paper'] {
    min-width: 230px;
    margin-top: 8px;
  }
  [class~='MuiAvatar-root'] {
    width: 45px;
    height: 45px;
  }
  li {
    padding: 8px 16px;
  }
  .logoutButton {
    margin: 0 16px;
  }
}
