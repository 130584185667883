.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.main {
  width: 100vw;
  height: calc(100vh - 64px);
  overflow: auto;
  //Use custom horizontal scrollbar
  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 56px);
  }
}
