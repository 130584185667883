.logo{
    display: block;
    width: 100%;
    text-align: center;
}
.logoImage {
  width: 100%;
  margin-bottom: 16px;
  max-width: 550px;
}

.title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
}
.groupTitle{
    display: flex;
    align-items: center;
    p{
        width: 50%;
    }
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
.itemContentCondition{
    .title{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
}
.disCondition{
    text-align: justify;
    p{
        font-size: 14px;
        font-weight: 400;
        padding-top: 8px;
    }
    
    
    /*@media screen and (max-width: 768px) {
        max-height: 50vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 10px;
            border: 2px solid white;
        }
    
        &::-webkit-scrollbar-track {
            background: white;
            border-radius: 10px;
        }
    }*/
}
.groupBtn{
    padding-top: 24px;
    display: flex;
    gap: 8px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
}
.tablePackage{
    width: 100%;
    padding-top: 8px;
    td{
        padding: 4px 0;
        font-size: 14px;
        font-weight: 400;
        .subtitle{
            max-width: 100%;
            white-space: normal;
        }  
    }
    .tdTitle{
        font-size: 16px;
        font-weight: 600;
    }
    .tdSubtitle{
        width: 40%;
    }
}