.subscriptionWrapper {
  padding: 24px;
  .cardHeader {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .title {
    font-weight: 600;
  }
  .description {
    font-size: 14px;
  }
  .buttonContainer {
    @media only screen and (min-width: 991.98px) {
      max-width: 300px;
    }
  }
  .error {
    font-weight: 600;
    color: var(--danger);
  }
  [class~='MuiDataGrid-columnHeaderTitleContainer'] div {
    font-weight: 600;
  }
}
