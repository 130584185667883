.topScrollBar {
  position: sticky;
  z-index: 999;
  left: 0;
  background: var(--backgroundLight);
  padding-right: 20px;
  padding-bottom: 8px;
  > div {
    height: 20px;
    overflow: auto;
  }
}

.tableWrapper {
  padding-bottom: 16px;
}
.table {
  .row {
    display: flex;
    align-items: stretch;
    background: var(--backgroundLight);
    min-height: 30px;
    &.desktopSticky {
      position: sticky;
    }
    &.borderTop {
      border-top: 2px solid var(--gray);
    }
    &.borderTopRed {
      border-top: 2px solid var(--primary);
    }
    &.borderTotal {
      border: 1px solid var(--primary);
      border-width: 2px 0;
    }
    &.borderChild {
      > div:first-child {
        border-left: 2px solid var(--primary);
      }
      > div:last-child {
        border-right: 2px solid var(--primary);
      }
    }
  }
  .column {
    flex-shrink: 0;
    min-width: 120px;
    flex: 1;
  }
  .topicName,
  .subTableName,
  .dataName {
    min-width: 250px;
    flex: 0;
    @media screen and (max-width: 768px) {
      min-width: 150px;
    }
  }
  .unit {
    min-width: 200px;
    flex: 0;
    .tooltip {
      cursor: default;
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      margin-right: 8px;
      padding: 2px 8px;
      border-radius: 50%;
      background-color: var(--grey);
    }
    .changeButton {
      padding: 2px;
      font-size: 12px;
      border-width: 1px;
    }
    @media screen and (max-width: 768px) {
      min-width: 150px;
    }
  }

  .head {
    height: 40px;
    display: flex;
    align-items: center;
    position: sticky;
    width: 100%;
    min-width: fit-content;
    background: var(--backgroundLight);
    z-index: 999;
    .column {
      cursor: default;
      text-align: right;
      padding: 4px 16px;
      font-size: 16px;
      font-weight: 600;
      color: var(--darkGray);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(2) {
        box-shadow: var(--rightBoxShadow);
      }
      &:first-child,
      &:nth-child(2) {
        cursor: pointer;
        z-index: 1;
        position: sticky;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 2px 32px 2px 16px;
        justify-content: start;
        background: var(--backgroundLight);
        transition: all 0.25s;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          .revert {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .body {
    border-radius: 8px 8px 0 0;
    min-height: 100%;
    min-width: fit-content;
    width: 100%;
    background: var(--backgroundLight);
    .topicWrapper:not(:last-child) {
      margin-bottom: 24px;
    }
    .topicName,
    .subTableName {
      min-width: fit-content;
      font-weight: bold !important;
      text-transform: uppercase;
    }
    .subTableName {
      padding: 4px 16px !important;
    }
    .dataName {
      min-width: 250px;
      flex: 0;
      @media screen and (max-width: 768px) {
        min-width: 150px;
      }
    }
    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .column {
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 8px;
      word-break: break-all;
      background-color: inherit;
      &.changeableValue {
        background-color: var(--white);
        &.changedValue {
          font-weight: 600;
          text-decoration: underline;
          text-underline-position: under;
          text-decoration-style: dashed;
          text-decoration-color: var(--gray);
          text-decoration-thickness: 1px;
        }
      }
      &:first-child,
      &:nth-child(2) {
        position: sticky;
        z-index: 1;
        > p {
          text-align: left;
        }
        @media screen and (max-width: 768px) {
          position: static;
        }
      }
      > p {
        flex: 1;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &:nth-child(2) {
        box-shadow: var(--rightBoxShadow);
        > div {
          width: 100%;
        }
      }
    }
  }
}
