.planWrapper {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--grey);
  box-shadow: var(--commonBoxShadow);
  background-color: var(--white);
  .planTitle {
    font-size: 24px;
    font-weight: 500;
    color: var(--darkGray);
  }
  .planSubtitle {
    font-size: 14px;
  }
  .dollar {
    margin-top: 4px;
    font-weight: 600;
    align-self: flex-start;
    color: var(--darkGray);
  }
  .price {
    line-height: 1.17;
    font-weight: 600;
    font-size: 48px;
    opacity: 0.7;
    color: var(--primary);
  }
  .duration {
    margin-bottom: 4px;
    font-weight: 600;
    align-self: flex-end;
    color: var(--darkGray);
  }
  .submitButton {
    border-radius: 4px;
    width: 100%;
    margin-top: 24px;
  }
}
