.container {
  z-index: 100;
}

.drawerPaper {
  background: var(--white);
  border: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 260px;
  height: 100%;
  box-shadow: var(--sidebarBoxShadow) !important;
  @media screen and (max-width: 600px) {
    width: 260px;
    display: block;
    height: 100vh;
    z-index: 100;
    visibility: visible;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0px;
    padding-left: 0px;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}

.logo {
  padding: 16px 8px 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
}

.list {
  padding: 0px;
  position: unset;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.item {
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  margin: 0 8px;
}

.itemLink {
  color: var(--black) !important;
  width: auto;
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  transition: all 300ms linear;
  font-weight: 600;
  line-height: 1.5em;
  border-radius: 3px;
  background-color: transparent;
}

.itemLinkActive {
  color: var(--white) !important;
  background: var(--primary) !important;
}
