.subscriptionRequired {
  position: sticky;
  left: 24px;
  margin: 24px 24px 0;
  padding: 16px;
  font-size: 14px;
  color: var(--brown);
  background-color: var(--yellowLight);
}

.dataInfo {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  .titleChangeNewScenario{
    font-size: 18px;
    font-weight: 600;
  }
  .countryList {
    padding-top: 7px;
    font-size: 16px !important;
    font-weight: 600 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  [class~='MuiAlert-message'] {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 12px;
  }
}

.downloadButtonWrapper {
  position: sticky;
  left: 0;
}

.downloadBtn {
  font-size: 14px;
  margin-left: auto;
  &.below {
    margin: 8px 16px 0 auto;
  }
  svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    path {
      fill: var(--white);
    }
  }
}
