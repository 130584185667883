.container {
  display: block;
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--white);
  background: var(--gradient);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.25s;
  &.fullWidth {
    width: 100%;
  }
  &:disabled {
    user-select: none;
    pointer-events: none;
    background: var(--gray);
  }
  &:hover {
    filter: brightness(1.15);
    transition: all 0.25s;
  }
}
