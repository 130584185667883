.container {
  .title {
    padding: 16px 24px 0;
    font-size: 20px;
    font-weight: 600;
  }
  .content {
    padding: 16px 24px;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > button {
    line-height: normal;
  }
}
