.profileWrapper {
  padding: 24px;
  .cardHeader {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
  }
}
.avatarWrapper {
  gap: 24px;
}
.avatar {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  > input {
    display: none;
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    transition: all 0.25s;
  }
  > svg {
    opacity: 0;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.25s;
  }
  &:hover {
    > img {
      filter: brightness(0.5);
      transition: all 0.25s;
    }
    > svg {
      opacity: 1;
      transition: all 0.25s;
    }
  }
}
