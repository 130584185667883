.logoImage {
  width: 100%;
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 24px;
}

.line {
  opacity: 0.7;
  margin: 16px 0 !important;
  border-color: var(--black) !important;
}

.resend {
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
  &.disabled {
    cursor: auto;
    color: var(--gray);
  }
}
