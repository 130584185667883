.displayNone{
    display: none;
}

.groupSelectCategory{
    display: flex;
    align-items: center;
    width: 100%;
    .lableSelect{
        margin-right: 16px;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
    }
    .customSelectCategory{
        flex: 1;
        div{
            padding: 8px;
        }
        
    }
    @media screen and (max-width: 768px) {
        display: block !important;
        .lableSelect{
            padding-bottom: 12px;
        }
        .customSelectCategory{
            width: 100%;
        }
    }
}