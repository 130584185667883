.modalDialog {
  position: fixed;
  top: 0;
  padding: 16px;
  background-color: var(--white);
  box-shadow: var(--chartModalBoxShadow);
  margin: 4px;
  z-index: 1000;
  width: 40%;
  min-width: 360px;
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .closeButton {
      margin-left: auto;
    }
  }
}
